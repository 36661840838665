/*
 Add pages in this object
 All carousel images should go into the respective folder on Google Cloud - (/mega-prod-assets.getmega.com/landing-page-images/)
 All popup images should go into src/images/popup/hero/
 Add custom static reviews in src/static-reviews/staticReviews.js
*/
const pages = {
  "dot-dash": {
    gameName: "Dots & Dash",
    descriptionText:
      "Are you bored & alone? Do you miss playing with your friends? Don't worry, GetMega Dots & Dash is here! For the first time in the world, play Dots & Dash on videochat & chill with your friends, in the comfort of your home. Create your private table, play live games, and hang out with your friends on videochat! 5,00,000+ players currently live on India's only social real money entertainment platform! Don't wait, videochat with your friends now!",
    descriptionList: [
      "100% safe, secure & private Dots & Dash games for you & your friends.",
      "Talk, watch, laugh and play Dots & Dash together on videochat in your private rooms!",
      "24-hour chat support, priority treatment for Dots & Dash Players.",
      "Smooth experience, awesome gameplay & non-stop winnings.",
      "Seamless withdrawal of winnings to Paytm, UPI, bank account, etc.",
      "Invite friends & win real cash when they join!",
    ],
    carouselImages: [
      "dot-dash/1.jpg",
      "dot-dash/2.jpg",
      "dot-dash/3.jpg",
      "dot-dash/4.jpg",
      "dot-dash/5.jpg",
      "dot-dash/6.jpg",
    ],
    staticTags: ["Social", "Board Games"],
    reviewTags: ["Social", "Multiplayer", "Fun"],
    popupImage: "carrom-girl.png",
    popupTitle: "Your friends are waiting, invite them now.",
    popupText:
      "Mat karo wait, call your friends & ho jao set. Make your Dots & Dash room in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest-social",
        forceUse: true,
        hostingSite: "download-app-dotdash-0",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://affiliate_asiasouth1.getmegacdn.com/latest-social",
        forceUse: false,
        hostingSite: "affiliate-download-dotdash",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  cards: {
    gameName: "",
    descriptionText:
      "Play Cards games on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India’s biggest cards tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    carouselImages: [
      "cards/1.jpg",
      "cards/2.jpg",
      "cards/3.jpg",
      "cards/4.jpg",
      "cards/5.jpg",
      "cards/6.jpg",
    ],
    staticTags: ["Card Games", "Win real cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "poker-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play high stakes poker to win bahut jyada cash. Next tournament starts in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appNqwhCawaU6zPpv",
    staticReviews: "cards",
    numOfDownloads: "50,00,000+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-stable-poker",
        forceUse: true,
        hostingSite: "download-app-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "affiliate-download-app-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate_ab: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "download-affiliate-app-cards",
        showPrivacyPolicy: true,
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        makeReviewsCollapsable: true,
        showAppInfo: true,
        cms: true,
        airtableBaseId: "app63cd0Z5hGSJJMy",
      },
      affiliate_hindi: {
        language: "hindi",
        getmegaText: "गेटमेगा",
        installText: "इंस्टॉल करें",
        readMoreText: "अधिक पढ़ें",
        collapseText: "ढहने",
        gamesType: "मेगा गेम्स",
        descriptionText:
          "खेलें पत्तों के गेम्स भारत के सबसे चहिते रियल मनी मनोरंजन ऍप पे। यहाँ हर रोज़ 10,000 से ज्यादा खिलाड़ी खेलते हैं। अपनी मर्ज़ी का टेबल चुनें - छोटे से बड़े दांव तक। अनगिनत पैसे कमाने का मौका पाएं।",
        descriptionList: [
          "देश के सबसे बड़े पत्तों के टेबल्स पे खेलें ♠️ ❤️",
          "सिर्फ 100% जांचे हुए प्रोफाइल्स, कोई नकली प्रोफाइल्स नहीं 👩 👨",
          "अपनी मर्ज़ी का दांव चुनें - छोटे, मध्यम, बड़े, और प्रचंड! 🏆",
          "24 घंटे लगातार चलनेवाले टेबल्स पे कभी भी खेलें ⏳",
          "किसी भी समस्या के लिए उच्च क्वालिटी चैट सपोर्ट 👨🏽‍💻",
          "भारत में 100% बनी हुई ऍप का समर्थन करें 🇮🇳",
        ],
        carouselImages: [
          "cards-hindi/1.jpg",
          "cards-hindi/2.jpg",
          "cards-hindi/3.jpg",
          "cards-hindi/4.jpg",
          "cards-hindi/5.jpg",
          "cards-hindi/6.jpg",
        ],
        staticTags: ["पत्तों के गेम्स", "जीतें असली कॅश"],
        reviewTags: ["सबसे तेज़ ट्रांस्फर", "सच्चे खिलाड़ी", "आकर्षक"],
        popupTitle: "रिया जीती ₹89,580 पेहले ही हफ्ते में!",
        popupText:
          "बड़े टेबल्स पे पोकर खेलके जीतें बहुत सारा कॅश. अगला टूर्नामेंट शुरू होनेवाला है:",
        popupDownloadText: "आजके डाउनलोड्स:",
        numOfDownloads: "500,000+",
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "affiliate-download-cards-hindi",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        totalReviews: "10,000 रेटिंग",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग और रिव्यु",
        mostMentionedText: "रिव्यु में सबसे ज्यादा ज़िक्र:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल",
        graphicsText: "ग्राफ़िक्स",
        disclaimerText:
          "इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और यह व्यसनी हो सकता है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।",
        privacyPolicyText: "गोपनीयता नीति",
        todayDownloadsText: "आजके डाउनलोड्स",
        joinNowText: "अब शामिल हों",
      },
      facebook_hindi: {
        language: "hindi",
        getmegaText: "गेटमेगा",
        installText: "इंस्टॉल करें",
        readMoreText: "अधिक पढ़ें",
        collapseText: "ढहने",
        gamesType: "मेगा गेम्स",
        descriptionText:
          "खेलें पत्तों के गेम्स भारत के सबसे चहिते रियल मनी मनोरंजन ऍप पे। यहाँ हर रोज़ 10,000 से ज्यादा खिलाड़ी खेलते हैं। अपनी मर्ज़ी का टेबल चुनें - छोटे से बड़े दांव तक। अनगिनत पैसे कमाने का मौका पाएं।",
        descriptionList: [
          "देश के सबसे बड़े पत्तों के टेबल्स पे खेलें ♠️ ❤️",
          "सिर्फ 100% जांचे हुए प्रोफाइल्स, कोई नकली प्रोफाइल्स नहीं 👩 👨",
          "अपनी मर्ज़ी का दांव चुनें - छोटे, मध्यम, बड़े, और प्रचंड! 🏆",
          "24 घंटे लगातार चलनेवाले टेबल्स पे कभी भी खेलें ⏳",
          "किसी भी समस्या के लिए उच्च क्वालिटी चैट सपोर्ट 👨🏽‍💻",
          "भारत में 100% बनी हुई ऍप का समर्थन करें 🇮🇳",
        ],
        carouselImages: [
          "cards-hindi/1.jpg",
          "cards-hindi/2.jpg",
          "cards-hindi/3.jpg",
          "cards-hindi/4.jpg",
          "cards-hindi/5.jpg",
          "cards-hindi/6.jpg",
        ],
        staticTags: ["पत्तों के गेम्स", "जीतें असली कॅश"],
        reviewTags: ["सबसे तेज़ ट्रांस्फर", "सच्चे खिलाड़ी", "आकर्षक"],
        popupTitle: "रिया जीती ₹89,580 पेहले ही हफ्ते में!",
        popupText:
          "बड़े टेबल्स पे पोकर खेलके जीतें बहुत सारा कॅश. अगला टूर्नामेंट शुरू होनेवाला है:",
        popupDownloadText: "आजके डाउनलोड्स:",
        numOfDownloads: "500,000+",
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-poker-hindi",
        forceUse: true,
        hostingSite: "download-app-cards-hindi",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        totalReviews: "10,000 रेटिंग",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग और रिव्यु",
        mostMentionedText: "रिव्यु में सबसे ज्यादा ज़िक्र:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल",
        graphicsText: "ग्राफ़िक्स",
        disclaimerText:
          "इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और यह व्यसनी हो सकता है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।",
        privacyPolicyText: "गोपनीयता नीति",
        todayDownloadsText: "आजके डाउनलोड्स",
        joinNowText: "अब शामिल हों",
      },
      facebook_marathi: {
        language: "marathi",
        getmegaText: "गेटमेगा",
        installText: "स्थापित करा",
        readMoreText: "पुढे वाचा",
        collapseText: "कोसळणे",
        gamesType: "मेगा गेम्स",
        descriptionText:
          "खेळा पत्त्यांचे गेम्स भारतातील सर्वांत लोकप्रिय रियल मनी मनोरंजन ऍपवर. इथे रोज 10,000+ खेळाडू खेळतात. आपल्या मर्जीनुसार टेबल निवडा - लहानापासून मोठ्या स्टेक्स पर्यंत. अमर्याद रक्कम जिंकण्याची संधी सोडू नका.",
        descriptionList: [
          "भारतातील सर्वात मोठ्या पत्त्यांच्या टेबल्स वर खेळा ♠️ ❤️",
          "फक्त 100% तपासणी झालेल्या खेळाडूंशीच खेळा, फेक प्रोफाईल्स नाहीत 👩 👨",
          "तुम्हाला हवे असलेलेच स्टेक्स निवडा - लहान, मध्यम, मोठे, आणि खूप मोठे 🏆",
          "24 तास सतत चालणाऱ्या टेबल्सवर कधीही खेळा ⏳",
          "कोणत्याही समस्येसाठी सर्वाधिक प्राधान्याची चॅट सपोर्ट 👨🏽‍💻",
          "100% भारतात विकसित झालेल्या ऍपला समर्थन द्या 🇮🇳",
        ],
        carouselImages: [
          "cards-marathi/1.jpg",
          "cards-marathi/2.jpg",
          "cards-marathi/3.jpg",
          "cards-marathi/4.jpg",
          "cards-marathi/5.jpg",
          "cards-marathi/6.jpg",
        ],
        staticTags: ["पत्त्यांचे गेम्स", "रोख रक्कम जिंका"],
        reviewTags: [
          "सगळ्यात लवकर पैसे ट्रांसफर",
          "100% अस्सल खेळाडू",
          "आकर्षक",
        ],
        popupTitle: "₹89,580 जिंकले रियाने, पहिल्याच आठवड्यात!",
        popupText:
          "खूप सारी रक्कम जिंकण्यासाठी पोकरच्या मोठ्या टेबल्सवर खेळा. पुढील टूर्नामेंट लवकरच सुरू होत आहे:",
        popupDownloadText: "आजचे  डाउनलोड्स:",
        numOfDownloads: "500,000+",
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-poker-marathi",
        forceUse: true,
        hostingSite: "download-app-cards-marathi",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        totalReviews: "10,000 रिव्यु",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग्ज आणि रिव्ह्यूज",
        mostMentionedText: "रिव्ह्यूजमध्ये सर्वाधिक उल्लेख:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल्स",
        graphicsText: "ग्राफिक्स",
        disclaimerText:
          "या गेममध्ये आर्थिक जोखीम आणि व्यसनाची शक्यता आहे. कृपया जबाबदारीने आणि आपल्या जोखमीवर खेळा.",
        privacyPolicyText: "गोपनीयता धोरण",
        todayDownloadsText: "आजचे  डाउनलोड्स",
        joinNowText: "आता सामील व्हा",
      },
    },
  },
  rummy: {
    gameName: "",
    descriptionText:
      "Play Rummy on India’s most loved real money entertainment platform. 10,000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India’s biggest Rummy tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    carouselImages: [
      "rummy/1.png",
      "rummy/2.png",
      "rummy/3.png",
      "rummy/4.png",
      "rummy/5.png",
      "rummy/6.png",
    ],
    staticTags: ["Card Games", "Win real cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "rummy-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play rummy on BIG tables to win loads of cash. Next tournament starts in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appzs5lZNAcpHlhyA",
    staticReviews: "rummy",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-stable-rummy",
        forceUse: true,
        hostingSite: "download-app-rummy",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "affiliate-download-app-rummy",
        disclaimer: "This app will be downloaded outside Google Play Store",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate_hindi: {
        language: "hindi",
        getmegaText: "गेटमेगा",
        installText: "इंस्टॉल करें",
        readMoreText: "अधिक पढ़ें",
        collapseText: "ढहने",
        gamesType: "रियल मनी गेम्स",
        descriptionText:
          "भारत के सबसे चहिते रियल मनी मनोरंजन ऍप पे रमी खेलें। यहाँ हर रोज़ 10,000 से ज्यादा खिलाडी खेलते हैं। अपनी मर्ज़ी का टेबल चुनें - छोटे से बड़े दांव तक। अनगिनत पैसे कमाने का मौका पाएं।",
        descriptionList: [
          "देश के सबसे बड़े टेबल्स पे खेलें ♠️ ❤️",
          "सिर्फ 100% जांचे हुए प्रोफाइल्स, कोई नकली प्रोफाइल्स नहीं 👩 👨",
          "अपनी मर्ज़ी का दांव चुनें - छोटे, मध्यम, बड़े, और प्रचंड! 🏆",
          "24 घंटे लगातार चलनेवाले टेबल्स पे कभी भी खेलें ⏳",
          "किसी भी समस्या के लिए उच्च अग्रता का चैट सपोर्ट 👨🏽‍💻",
          "भारत में 100% बनी हुई ऍप का समर्थन करें 🇮🇳",
        ],
        carouselImages: [
          "rummy-hindi/1.png",
          "rummy-hindi/2.png",
          "rummy-hindi/3.png",
          "rummy-hindi/4.png",
          "rummy-hindi/5.png",
          "rummy-hindi/6.png",
        ],
        staticTags: ["पत्तों के गेम्स", "जीतें असली कॅश"],
        reviewTags: ["सबसे तेज़ ट्रांस्फर", "सच्चे खिलाड़ी", "आकर्षक"],
        popupTitle: "रिया जीती ₹89,580 पेहले ही हफ्ते में!",
        popupText:
          "बड़े टेबल्स पे रमी खेलके जीतें बहुत सारा कॅश. अगला टूर्नामेंट शुरू होनेवाला है:",
        popupDownloadText: "आजके डाउनलोड्स:",
        vimeoVideoId: "537657601",
        numOfDownloads: "500,000+",
        installUrl: "https://getmega.onelink.me/shOD/SEOListing",
        forceUse: true,
        hostingSite: "affiliate-download-rummy-hindi",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        totalReviews: "10,000 रेटिंग",
        downloadsText: "डाउनलोड्स",
        ratingsHeading: "रेटिंग और रिव्यु",
        mostMentionedText: "रिव्यु में सबसे ज्यादा ज़िक्र:",
        gameplayText: "गेमप्ले",
        controlsText: "कंट्रोल",
        graphicsText: "ग्राफ़िक्स",
        disclaimerText:
          "इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और यह व्यसनी हो सकता है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।",
        privacyPolicyText: "गोपनीयता नीति",
        todayDownloadsText: "आजके डाउनलोड्स",
        joinNowText: "अब शामिल हों",
      },
    },
  },
  carrom: {
    gameName: "Carrom",
    descriptionText:
      "Play Carrom on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Only 100% genuine verified players",
      "Exciting rooms non stop: all night, all day",
      "Big tables, for Big League",
      "Seamless withdrawal to Paytm, Bank account etc",
      "Instant deposits, seamlessly",
      "24-hour chat support, priority treatment for Carrom Players",
    ],
    carouselImages: [
      "carrom/1.png",
      "carrom/2.png",
      "carrom/3.png",
      "carrom/4.png",
      "carrom/5.png",
      "carrom/6.png",
      "carrom/7.png",
      "carrom/8.png",
      "carrom/9.png",
    ],
    staticTags: ["Board Games", "Win real cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "carrom-girl.png",
    popupTitle: "Sirf is Carrom game se Neha won ₹89,580 in 9 days",
    popupText:
      "Mat karo wait, show your carrom skills now. Next carrom tournament starts in:",
    vimeoVideoId: "427996732",
    gtagId: "UA-140605344-9",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "carrom",
    numOfDownloads: "500K+",
    variants: {},
  },
  "carrom-videochat": {
    gameName: "Carrom",
    descriptionText:
      "Are you bored & alone? Do you miss playing with your friends? Don't worry, GetMega Carrom is here! For the first time in the world, play Carrom on videochat & chill with your friends, in the comfort of your home. Create your private table, play live games, and hang out with your friends on videochat! 5,00,000+ players currently live on India's only social real money entertainment platform! Don't wait, videochat with your friends now!",
    descriptionList: [
      "100% safe, secure & private Carrom games for you & your friends.",
      "Talk, watch, laugh and play Carrom together on videochat in your private rooms!",
      "24-hour chat support, priority treatment for Carrom Players.",
      "Smooth experience, awesome gameplay & non-stop winnings.",
      "Seamless withdrawal of winnings to Paytm, UPI, bank account, etc.",
      "Invite friends & win real cash when they join!",
    ],
    carouselImages: [
      "carrom-videochat/1.png",
      "carrom-videochat/2.png",
      "carrom-videochat/3.png",
      "carrom-videochat/4.png",
      "carrom-videochat/5.png",
      "carrom-videochat/6.png",
      "carrom-videochat/7.png",
    ],
    staticTags: ["Social", "Board Games"],
    reviewTags: ["Social", "Multiplayer", "Fun"],
    popupImage: "carrom-girl.png",
    popupTitle: "Your friends are waiting, invite them now.",
    popupText:
      "Mat karo wait, call your friends & ho jao set. Make your Carrom room in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "carrom",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-carrom",
        forceUse: true,
        hostingSite: "download-app-carrom-videochat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      fb_stability: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-carrom-stable",
        forceUse: true,
        hostingSite: "download-carrom-video-chat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-carrom-new",
        forceUse: false,
        hostingSite: "affiliate-carrom-videochat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      "getmega.in": {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-carrom-fb",
        forceUse: false,
        hostingSite: "download-app-carrom-videochat2",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  pool: {
    gameName: "Pool",
    descriptionText:
      "Play Pool on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Only 100% genuine verified players",
      "Exciting rooms non stop: all night, all day",
      "Big tables, for Big League",
      "Seamless withdrawal to Paytm, Bank account etc",
      "Instant deposits, seamlessly",
      "24-hour chat support, priority treatment for Pool Players",
    ],
    carouselImages: [
      "pool/1.png",
      "pool/2.png",
      "pool/3.png",
      "pool/4.png",
      "pool/5.png",
      "pool/6.png",
      "pool/7.png",
      "pool/8.png",
      "pool/9.png",
      "pool/10.png",
    ],
    staticTags: ["Pool", "Win Real Cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "pool-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play classy Pool to win tonnes of money. Next tournament starts in:",
    vimeoVideoId: "342926432",
    gtagId: "AW-718511341",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-pool",
        forceUse: true,
        hostingSite: "download-app-pool",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  fruitkaat: {
    gameName: "Fruit Kaat",
    descriptionText:
      "Play Fruit Kaat on India’s most loved real money entertainment platform. 10000+ players are playing every day. Unlimited Real Cash to be won!",
    descriptionList: [
      "Only 100% genuine verified players",
      "Exciting rooms non stop: all night, all day",
      "Big tables, for Big League",
      "Seamless withdrawal to Paytm, Bank account etc",
      "Instant deposits, seamlessly",
      "24-hour chat support, priority treatment for Fruit Kaat Players",
    ],
    carouselImages: [
      "fruitkaat/1.png",
      "fruitkaat/2.jpg",
      "fruitkaat/3.jpg",
      "fruitkaat/4.jpg",
      "fruitkaat/5.jpg",
      "fruitkaat/6.png",
      "fruitkaat/7.png",
    ],
    staticTags: ["Social", "Win Real Cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "fruitkaat-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play classy Fruit Kaat to win tonnes of money. Next tournament starts in:",
    vimeoVideoId: "342926432",
    gtagId: "AW-718511341",
    airtableName: "Fruitkaat LP Reviews",
    airtableBaseId: "appWwwjouUr30XT1w",
    staticReviews: "fruitkaat",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest-social",
        forceUse: true,
        hostingSite: "download-app-fruitkaat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  trivia: {
    gameName: "Trivia",
    descriptionText:
      "Miss hanging out with your friends? Love Trivia games, defeating friends at Poker, or smashing the Carrom board? Install GetMega, the only social network for real money gamers! 🎮 , Play games on videochat & chill with your friends, anytime, anywhere. Add friends, create groups & make your own game show! Play live with friends on voice & videochat, and win their money. 😉  50,00,000+ players are already on GetMega, and so are your friends! Don't wait, beat your friends with your brains & watch them go crazy on videochat! Play thrilling card games like Poker & Rummy ♠️ or fun casual games like Trivia, Carrom, Pool & more 🎱 ",
    descriptionList: [
      " Win money with your gaming skills",
      "💯 safe & secure private games for you & your friends",
      "Add your friends instantly 🕺💃🕺",
      "Talk, laugh & chill on video & audio chat in your private games!",
      "Smooth experience, awesome gameplay & non-stop winnings 🏆",
      "24x7 chat support 🎧",
      "Seamless withdrawal of your winnings to Paytm / UPI / Bank account 🏧",
      "Invite more friends & win more! ♥️",
    ],
    carouselImages: [
      "trivia/1.png",
      "trivia/2.png",
      "trivia/3.png",
      "trivia/4.png",
      "trivia/5.png",
      "trivia/6.png",
      "trivia/7.png",
    ],
    staticTags: ["Social", "Win Real Cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "fruitkaat-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play classy Trivia to win tonnes of money. Next tournament starts in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Trivia LP Reviews",
    airtableBaseId: "appiGFPcboJUa6eS0",
    staticReviews: "trivia",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-trivia",
        forceUse: true,
        hostingSite: "download-app-trivia",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
}

module.exports = pages
